import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './style.css';

export interface Props {}

interface State {
  collapsed: boolean;
}

export class Navigation extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      collapsed: false,
    };
  }

  toggleNavbar = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    const collapsed = this.state.collapsed;

    return (
      <div id="mainNav" className="navbar navbar-expand-lg navbar-light fixed-top navbar-shrink">
        <div className="container">
          <a id="nav-logo" className="navbar-brand" href="#">
            Chucks
          </a>
          <button
            onClick={this.toggleNavbar}
            className={`navbar-toggler navbar-toggler-right ${collapsed && 'collapsed'}`}
            type="button"
            data-toggle="collapse"
            data-target="#navBarResponsive"
            aria-controls="navBarResponsive"
            aria-expanded="false"
            aria-label="Toggle Navigation">
            <span className="navbar-toggler-icon" />
          </button>
          <div className={`collapse navbar-collapse ${collapsed && 'show'}`} id="navBarResponsive">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#services">
                  Services
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#faq">
                  FAQs
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#contact">
                  Contact Us
                </a>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/terms">
                  T & C
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/privacy">
                  Privacy
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
