import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

import './style.css';

export class Contact extends Component {
  render() {
    return (
      <div className="container">
        <div className="section-header">
          <h2
            className="section-title wow fade-in-down animated section-animated"
            data-wow-delay="0.3s">
            Contact Us
          </h2>
          <div className="shape wow fade-in-down animated section-animated" data-wow-delay="0.3s" />
        </div>
        <div className="row">
          <div className="col-md-4">
            <FontAwesomeIcon icon={faMapMarkerAlt} />
            <p>Diepkloof, Soweto</p>
          </div>
          <div className="col-md-4">
            <FontAwesomeIcon icon={faEnvelope} />
            <p>info@chucksapp.co.za</p>
          </div>
          <div className="col-md-4">
            <FontAwesomeIcon icon={faPhone} />
            <p>0711668815</p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-7 col-md-12 col-sm-12">
            <form id="contactForm" noValidate>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      placeholder="Name"
                      required
                      data-error="Please enter your name"
                    />
                    <div className="help-block with-errors" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="email"
                      name="email"
                      placeholder="Email"
                      required
                      data-error="Please enter your email"
                    />
                    <div className="help-block with-errors" />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="msg_subject"
                      placeholder="Subject"
                      required
                      data-error="Please enter your subject"
                    />
                    <div className="help-block with-errors" />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      id="message"
                      placeholder="Your Message"
                      rows={7}
                      required
                      data-error="Write your message"
                    />
                    <div className="help-block with-errors" />
                  </div>
                  <div className="submit-button text-left">
                    <button className="btn btn-common disabled" id="form-submit" type="submit">
                      Send Message
                    </button>
                    <div id="msgSubmit" className="h3 text-center hidden" />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="col-lg-5 col-md-12 col-xs-12">
            <iframe
              width="100%"
              height="350"
              frameBorder="0"
              src="https://www.google.com/maps/embed/v1/place?zoom=14&q=Diepkloof,Soweto&key=AIzaSyCa8sJg4zhJciGSW10yRFVbeC3TxCeCI70"
              allowFullScreen
            />
          </div>
        </div>
      </div>
    );
  }
}
