import React, { Component } from 'react';

import './style.css';

export class Terms extends Component {
  render() {
    return (
      <div className="container">
        <h1>Terms & Conditions</h1>

        <h4>Introduction</h4>

        <h5>Company Details</h5>

        <p>
          This website is run by Chucks Service (Pty) Ltd based in South Africa trading as Chucks
          Service and with registration number 2017/334104/07 and Sizwe Khumalo and Azile Silwana as
          Owners.
        </p>

        <p>
          This website is governed by the laws of South Africa and Chucks Service chooses as its
          domicilium citandi et executandi for all purposes under this agreement, whether in respect
          of court process, notice, or other documents or communication of whatsoever nature.
        </p>

        <p>Chucks Service is a business in the Food ordering and delivery industry.</p>

        <h5>Payments</h5>

        <p>Payment may be made via Visa and MasterCard.</p>

        <p>
          Card transactions will be acquired for Chucks Service via PayGate (Pty) Ltd who are the
          approved payment gateway for all South African Acquiring Banks. PayGate uses the strictest
          form of encryption, namely Secure Socket Layer 3 (SSL3) and no Card details are stored on
          the website. Users may go to www.paygate.co.za to view their security certificate and
          security policy.
        </p>

        <p>
          Customer details will be stored by Chucks Service separately from card details which are
          entered by the client on PayGate’s secure site. For more detail on PayGate refer to
          www.paygate.co.za.
        </p>

        <p>
          The merchant outlet country at the time of presenting payment options to the cardholder is
          South Africa. Transaction currency is South African Rand (ZAR).
        </p>

        <p>
          Chucks Service takes responsibility for all aspects relating to the transaction including
          sale of goods and services sold on this website, customer service and support, dispute
          resolution and delivery of goods.
        </p>

        <p>
          Cancellation of orders by the client will attract a 3% administration fee and refunds will
          be paid in full within 30 days.
        </p>

        <h4>Orders</h4>

        <p>
          Registered users may place orders for Food, which the Restaurant and/or Chucks Service may
          accept or reject. The placing of an order will happen when payment is made by credit card,
          or when payment by cash is confirmed on the App or Telephone Service. Whether or not the
          Restaurant and/or Chucks Service accepts an order depends on various factors, including
          but not limited to, the availability of Food, capacity for Courier Delivery, correctness
          of the information relating to the Food (such as the price) and receipt of payment or
          payment authorisation (if applicable) by Chucks Service for the Food.
        </p>

        <p>
          Chucks Service relies on information supplied to it by the Restaurants relating to the
          Food, and Chucks Service accordingly bears no liability for any inaccuracies in such
          information supplied to it. You acknowledge that stock of all Food on offer may be
          limited. Chucks Service will take reasonable efforts to ensure that when Food is no longer
          available, offers thereof are discontinued on the App. However, we cannot guarantee the
          availability of Food. When Food is no longer available after you have placed an order,
          Chucks Service will notify you thereof and reject your order or cancel the Sale, in which
          event you will be entitled to a refund of the amount paid by you for such order.
        </p>

        <p>
          It is your responsibility to establish from the relevant Restaurant which ingredients will
          be used when preparing the Food and make the necessary arrangements with such Restaurant
          to exclude any ingredients if you have any allergies or special requirements. We cannot
          and do not guarantee that the Food will be free of allergens and we disclaim any liability
          relating to any ingredients or allergens contained in Food.
        </p>

        <p>
          Should you wish to retract your order, you should contact Chucks Service via telephone
          immediately, and Chucks Service will use its reasonable endeavours to effect such
          retraction. You will not be able to retract your order via the App, or after an Acceptance
          Notice has been generated. You will not be able to change your order after placing it, but
          may be able to retract it (subject to the above), and place a new one.
        </p>

        <h4>General</h4>

        <p>
          By downloading or using the app, these terms will automatically apply to you – you should
          make sure therefore that you read them carefully before using the app. You’re not allowed
          to copy, or modify the app, any part of the app, or our trademarks in any way. You’re not
          allowed to attempt to extract the source code of the app, and you also shouldn’t try to
          translate the app into other languages, or make derivative versions. The app itself, and
          all the trade marks, copyright, database rights and other intellectual property rights
          related to it, still belong to Chucks Service.
        </p>
        <p>
          Chucks Service is committed to ensuring that the app is as useful and efficient as
          possible. For that reason, we reserve the right to make changes to the app or to charge
          for its services, at any time and for any reason. We will never charge you for the app or
          its services without making it very clear to you exactly what you’re paying for.
        </p>
        <p>
          The Chucks Service app stores and processes personal data that you have provided to us, in
          order to provide our Service. It’s your responsibility to keep your phone and access to
          the app secure. We therefore recommend that you do not jailbreak or root your phone, which
          is the process of removing software restrictions and limitations imposed by the official
          operating system of your device. It could make your phone vulnerable to
          malware/viruses/malicious programs, compromise your phone’s security features and it could
          mean that the Chucks Service app won’t work properly or at all.
        </p>
        <p>
          You should be aware that there are certain things that Chucks Service will not take
          responsibility for. Certain functions of the app will require the app to have an active
          internet connection. The connection can be Wi-Fi, or provided by your mobile network
          provider, but Chucks Service cannot take responsibility for the app not working at full
          functionality if you don’t have access to Wi-Fi, and you don’t have any of your data
          allowance left.
        </p>
        <p>
          If you’re using the app outside of an area with Wi-Fi, you should remember that your terms
          of the agreement with your mobile network provider will still apply. As a result, you may
          be charged by your mobile provider for the cost of data for the duration of the connection
          while accessing the app, or other third party charges. In using the app, you’re accepting
          responsibility for any such charges, including roaming data charges if you use the app
          outside of your home territory (i.e. region or country) without turning off data roaming.
          If you are not the bill payer for the device on which you’re using the app, please be
          aware that we assume that you have received permission from the bill payer for using the
          app.
        </p>
        <p>
          Along the same lines, Chucks Service cannot always take responsibility for the way you use
          the app i.e. You need to make sure that your device stays charged – if it runs out of
          battery and you can’t turn it on to avail the Service, Chucks Service cannot accept
          responsibility.
        </p>
        <p>
          With respect to Chucks Service’s responsibility for your use of the app, when you’re using
          the app, it’s important to bear in mind that although we endeavour to ensure that it is
          updated and correct at all times, we do rely on third parties to provide information to us
          so that we can make it available to you. Chucks Service accepts no liability for any loss,
          direct or indirect, you experience as a result of relying wholly on this functionality of
          the app.
        </p>
        <p>
          At some point, we may wish to update the app. The app is currently available on Android &
          iOS – the requirements for both systems (and for any additional systems we decide to
          extend the availability of the app to) may change, and you’ll need to download the updates
          if you want to keep using the app. Chucks Service does not promise that it will always
          update the app so that it is relevant to you and/or works with the Android & iOS version
          that you have installed on your device. However, you promise to always accept updates to
          the application when offered to you, We may also wish to stop providing the app, and may
          terminate use of it at any time without giving notice of termination to you. Unless we
          tell you otherwise, upon any termination, (a) the rights and licenses granted to you in
          these terms will end; (b) you must stop using the app, and (if needed) delete it from your
          device.
        </p>

        <h4>Changes to This Terms and Conditions</h4>

        <p>
          We may update our Terms and Conditions from time to time. Thus, you are advised to review
          this page periodically for any changes. We will notify you of any changes by posting the
          new Terms and Conditions on this page. These changes are effective immediately after they
          are posted on this page.
        </p>

        <h4>Contact Us</h4>

        <p>
          If you have any questions or suggestions about our Terms and Conditions, do not hesitate
          to contact us at support@chucksapp.co.za or at 072 166 8815. The company is located at
          5019 Ihlokohloko Street, Protea Glen Ext 4, Chiawelo, 1818.
        </p>
      </div>
    );
  }
}
