import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faHamburger, faUsers } from '@fortawesome/free-solid-svg-icons';

import app_store_logo from '../../img/app_store_logo.svg';
import play_store_logo from '../../img/play_store_logo.png';
import './style.css';

export class Services extends Component {
  render() {
    return (
      <div className="container">
        <div className="section-header">
          <h2
            className="section-title wow fade-in-down animated section-animated"
            data-wow-delay="0.3s">
            Our Services
          </h2>
          <div className="shape wow fade-in-down animated section-animated" data-wow-delay="0.3s" />
        </div>
        <div className="row">
          <div className="col-lg-6">
            <h4>Business</h4>
            <div className="services-item row">
              <div className="icon">
                <div className="centre">
                  <FontAwesomeIcon color="#400461" size="2x" icon={faBuilding} />
                </div>
              </div>
              <h4>Add food outlet profile</h4>
            </div>
            <div className="services-item row">
              <div className="icon">
                <div className="centre">
                  <FontAwesomeIcon color="#400461" size="2x" icon={faHamburger} />
                </div>
              </div>
              <h4>Add outlet menu</h4>
            </div>
            <div className="services-item row">
              <div className="icon">
                <div className="centre">
                  <FontAwesomeIcon color="#400461" size="2x" icon={faUsers} />
                </div>
              </div>
              <h4>Add employee profiles</h4>
            </div>
            <div className="services-item row">
              <div className="icon">
                <div className="centre">
                  <FontAwesomeIcon color="#400461" size="2x" icon={faHamburger} />
                </div>
              </div>
              <h4>Start receiving orders</h4>
            </div>
            <p>No monthly fees. No commission. Keep all your profits.</p>
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=za.co.chucksapp.business&hl=en&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
              <img className="image" alt="Get it on Google Play" src={play_store_logo} />
            </a>
          </div>
          <div className="col-lg-6">
            <h4>Customer</h4>
            <div className="services-item row">
              <div className="icon">
                <div className="centre">
                  <FontAwesomeIcon color="#400461" size="2x" icon={faBuilding} />
                </div>
              </div>
              <h4>See nearby food outlets</h4>
            </div>
            <div className="services-item row">
              <div className="icon">
                <div className="centre">
                  <FontAwesomeIcon color="#400461" size="2x" icon={faHamburger} />
                </div>
              </div>
              <h4>Place order</h4>
            </div>
            <div className="services-item row">
              <div className="icon">
                <div className="centre">
                  <FontAwesomeIcon color="#400461" size="2x" icon={faHamburger} />
                </div>
              </div>
              <h4>Track order</h4>
            </div>
            <div className="services-item row">
              <div className="icon">
                <div className="centre">
                  <FontAwesomeIcon color="#400461" size="2x" icon={faHamburger} />
                </div>
              </div>
              <h4>Receive order</h4>
            </div>
            <p>Wherever, Whenever.</p>
            <div className="row">
              <div className="col-sm-6">
                <a href="#">
                  <img className="image" alt="Download on the App Store" src={app_store_logo} />
                </a>
              </div>
              <div className="col-sm-6">
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=za.co.chucksapp&hl=en&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                  <img className="image" alt="Get it on Google Play" src={play_store_logo} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
