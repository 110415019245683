import React, { Component } from "react";

import "./style.css";

export class Faq extends Component {
  render() {
    return (
      <div className="container">
        <div className="section-header">
          <h2
            className="section-title wow fade-in-down animated section-animated"
            data-wow-delay="0.3s"
          >
            FAQs
          </h2>
          <div
            className="shape wow fade-in-down animated section-animated"
            data-wow-delay="0.3s"
          />
        </div>
        <div className="content">
          <h3>How far do you deliver?</h3>
          <h4>
            Currently we deliver up to 15km from the outlet's location. We do,
            however, accept special delivery requests outside of this.
          </h4>
        </div>
        <div className="content">
          <h3>How much is delivery? </h3>
          <h4>
            Our fees are determined by distance and size of the basket. In
            general, our delivery fees start from R10 for the shortest
            distances. There is also a service fee charge of 5% per order, which
            is added on checkout for the customer.
          </h4>
        </div>
        <div className="content">
          <h3>Do you only deliver food?</h3>
          <h4>
            No. We move everything that moves and can fit in our delivery box.
          </h4>
        </div>
        <div className="content">
          <h3>Do you deliver alcohol?</h3>
          <h4>We expect alcohol deliveries to start in August 2021.</h4>
        </div>
        <div className="content">
          <h3>What if I want to drive for Chucks?</h3>
          <h4>
            We are always in the lookout to fill our driver pool. Please forward
            your CV to careers@chucksapp.co.za
          </h4>
        </div>
        <div className="content">
          <h3>What are your delivery times?</h3>
          <h4>Deliveries start at 10am to 8pm everyday.</h4>
        </div>
        <div className="content">
          <h3>Where can I get your app?</h3>
          <h4>Our apps can be download from Google Play and the App Store.</h4>
        </div>
      </div>
    );
  }
}
