import React, { Component } from 'react'

import MashTunBanner from '../../img/mash_tun_banner.jpg'

import './style.css'

export class Home extends Component {
  render() {
    return (
      <div className="section-container">
        <div className="banner">
          <img alt="" src={MashTunBanner} />
        </div>
        <div className="content">
          <div className="col-md-5">
            <h1 className="">Chucks</h1>
            <p className="">A new way to order your KOTA</p>
            <p>
              Remember the last time you went to buy from your favourite Kasi cuisine? Or a Kota
              outlet? How long did you have to wait for your order? Not anymore. Order from the
              comfort of your couch, and we'll let you know when it's time to chow.
            </p>
          </div>
        </div>
        <div className="product-device shadow-sm d-none d-md-block" />
        {/* <div className="product-device product-device-2 shadow-sm d-none d-md-block" /> */}
      </div>
    )
  }
}
