import React, { Component } from 'react'
import { Navigation } from './components/Navigation'
import { Home } from './components/Home'
import { Services } from './components/Services'
import { Faq } from './components/Faq'
import { Contact } from './components/Contact'

import MastercardLogo from './img/mastercard_logo.jpg'
import MastercardSecurecodeLogo from './img/mastercard_securecode_logo.jpg'
import PaygateLogo from './img/paygate_logo.jpg'
import VerifiedByVisaLogo from './img/verified_by_visa_logo.jpg'
import VisaLogo from './img/visa_logo.jpg'

import './App.css'

export default class App extends Component {
  render() {
    return (
      <div className="App">
        <header>
          <Navigation />
          <Home />
        </header>
        <section id="services" className="section-padding bg-gray">
          <Services />
        </section>
        <section id="faq" className="section-padding">
          <Faq />
        </section>
        <section id="contact" className="section-padding bg-gray">
          <Contact />
        </section>
        <footer>
          <div className="container copyright-content">
            <div className="row">
              <div className="col-sm-auto mx-auto">
                <img src={PaygateLogo} />
              </div>
              <div className="col-sm-auto mx-auto">
                <img src={VisaLogo} />
              </div>
              <div className="col-sm-auto mx-auto">
                <img src={MastercardLogo} />
              </div>
              <div className="col-sm-auto mx-auto">
                <img src={VerifiedByVisaLogo} />
              </div>
              <div className="col-sm-auto mx-auto">
                <img src={MastercardSecurecodeLogo} />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <p>Copyright © {new Date().getFullYear()} All Right Reserved</p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    )
  }
}
